@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;


.label {
  margin-bottom: $spacing-04;
  margin-left: $spacing-05;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #161616;
}

.group-attendance-buttons {
  margin-bottom: $spacing-04;
}

.buttons {
  display: flex;
  margin-bottom: $spacing-03;
}


.attendance-radio-button {
  cursor: pointer;
  text-align: center;
  appearance: none;
  border-radius: 25px;
  padding: 7px 14px;
  border:1px solid #8D8D8D;
  color: #8D8D8D;
  font-size: 13px;
  transition: all 0.1s;
  width: 40px;
  height: 32px;

  margin-left: $spacing-05;
}

.buttons input:checked {
  color: #ffff;
  background: #0f62fe;
}

.buttons input::before {
  content: attr(label);
}