@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/breakpoint' as *;




.h1-form {
  padding-left: $spacing-04;
  @include breakpoint-down('md') {
    margin-bottom: $spacing-07;
  }
  @include breakpoint-down('lg') {
    margin-bottom: $spacing-07;
  }
  margin-bottom: $spacing-08;
}
.h4-form {
  padding-left: $spacing-04;
  @include breakpoint-down('md') {
    margin-bottom: $spacing-06;
  }
  @include breakpoint-down('lg') {
    margin-bottom: $spacing-06;
  }
  margin-bottom: $spacing-07;
}

.add-guide-form {
  @include breakpoint-down('md') {
    margin-bottom: $spacing-07;
  }
  @include breakpoint-down('lg') {
    margin-bottom: $spacing-07;
  }
  margin-bottom: $spacing-08;
}

.add-guide-column {
  display: inline;

  /* applies styles inside mixin on md breakpoint or below, like max-width */
  @include breakpoint-down('md') {
    margin-bottom: $spacing-06;
    max-width: 15rem;
  }
}