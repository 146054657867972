@use './overrides.scss';
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;


.booking-box {
  display: grid;
  justify-content: center;
  max-width: 398px;
  border-bottom: 1px solid #D9D9D9;
}

.booking {
  display: flex;
  justify-content: flex-start;
  max-width: 398px;
  padding-top: $spacing-04;
  padding-bottom: $spacing-04;
  padding-left: $spacing-05;
  padding-right: $spacing-05;
}

.booking-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 329px;
 }

.booking-content-left {
  display: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 160px;
  min-width: 241px;
}

.booking-name {
  font-size: 14px;
  font-weight: 500;
  max-width: 241px;
  min-width: 160px;
  text-align: left;
  color: #161616;
}

.booking-text-footer {
  display: flex;
  align-items: center;
  margin-top: $spacing-03;
  min-width: 160x;
  max-width: 241px;
}

.booking-text-footer-left {
  font-size: 13px;
  max-width: 92px;
  text-align: left;
}

.booking-attendance {
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  border:none;
  justify-content: flex-start;
  // margin-left: $spacing-02;
  max-width: 90px;
}

.booking-pax {
  font-size: 13px;
  font-weight: 450;
  text-align: left;
  max-width: 47px;
  color: #0f62fe;
}

.booking-edit-icon {
  margin-left: $spacing-02;
  position: relative;
  color: #0f62fe;
}

.booking-pax-icon {
  color: #0f62fe;
  padding-bottom: $spacing-01;
}

.dropdown {
  position: relative;
}

.menu {
  // position: absolute;
  display: block;
  list-style-type: none;
  // padding: 0;
  // width: 150px;
}

.booking-modified-tooltip-trigger {
  background: none;
  border: none;
}

.modifield-field {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-03;
  margin-left: $spacing-05;
  margin-top: $spacing-01;
}

.font-13 {
  font-size: 13px;
}
// .menu > li {
//   margin: 0;

//   background-color: white;
// }

// .menu > li:hover {
//   background-color: lightgray;
// }

// .menu > li > button {
//   width: 100%;
//   height: 100%;
//   text-align: left;

//   background: none;
//   color: inherit;
//   border: none;
//   padding: 5px;
//   margin: 0;
//   font: inherit;
//   cursor: pointer;
// }