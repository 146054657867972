@use '@carbon/react/scss/spacing' as *;

.cds--toggle__label-text {
  margin-bottom: 0.625rem;
  margin-top: $spacing-01;
  font-weight: 400;
  color: #161616;
  text-align: right;
}

.cds--toggle {
  margin-left: $spacing-07;
}

.cds--toggle__appearance {
  column-gap: 0rem;
}

.cds--toggle__switch {
  margin-left: $spacing-03;
}
.cds--toggle__switch--checked {
  background-color: var(--cds-support-success, #0f62fe);
}