@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;


.report-form {
  max-width: 25rem;
  background-color: #F4F4F4;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: $spacing-05;

}

.form-heading {
  margin-bottom:$spacing-05;
  @include type-style('heading-05');
}

.form-button {
  padding-left: -$spacing-05;
}


//TODO improve radio styles
.radio-form-group {
  height: 80px;
}
.radio {
  margin-top: $spacing-03;
  margin-bottom: $spacing-05;
}

.radio-elements {
  display: flex;
  align-items: center;
}

.radio-text {
  font-size: 14px;
}

.radio-input {
  margin: 2px 8px 2px 0px;
}

.form-info {
  color: #6F6F6F;
  font-size: 12px;
}