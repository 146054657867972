@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;


.number-picker-frame {
  margin-left: 1rem;
  align-items:flex-start;
  border-radius: 11.2px;
  display:flex;
  justify-content:flex-start;
  flex-direction:row;
  max-width: 160px;
  height: 40px;
}

.number-picker {
  align-items: flex-start;
  display: flex;
  align-items: center;
  gap: $spacing-03;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 160px;
}


//TODO button not selectable when max o min is reached
.number-picker-step-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; // Added this line
  outline: none; // Added this line
  width: 36px;
  height: 36px;
  max-width: 160px;
  border-color: #8D8D8D;
  padding: $spacing-03;
  border-radius: 50%;
  border: 2px solid #8D8D8D;
  &:hover,
  &:focus {
    border: 2px solid #0f62fe;
    border-color: #0f62fe; 
    outline: none
  }
}

.editable-input {
  align-items: center;
  border-width: 2px;
  border-color: #8D8D8D;
  border-radius: 11.2px;
  border-style: solid;
  display: flex;
  flex-direction: row;
  // position: absolute;
  justify-content: center;
  max-width: 80px;
  padding: 12px;
  -webkit-appearance: none;
  appearance: none;
  height: 40px;

  &:hover,
  &:focus {
    border-color: #0f62fe; 
    outline: none
  }
}

.number-input-icon {
  color: #161616;
}

.editable-input[type="number"]::-webkit-inner-spin-button,
.editable-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.editable-input[type="number"] {
  text-align: center;
}
