@use '@carbon/react/scss/spacing' as *;

.time-nearby-tours {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: $spacing-04;
}

.time-icon {
  margin-right: $spacing-02; 
}

.nearby-tour-link {
  margin-left: $spacing-03;
}