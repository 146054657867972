@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;


.link-booking {
  display: flex;
  justify-content: center;
}

.link-box-container {
  margin-top: $spacing-07;
  display: flex;
  box-shadow: rgb(0 0 0/8%) 0 1px 0;
}

.link-box-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 329px;
  padding-top: $spacing-04;
  padding-bottom: $spacing-04;
  padding-left: $spacing-05;
  padding-right: $spacing-05;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;  
  background-color: white;
}

.link-box-content-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 297px;
}


.link-box-content-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 241px;
}

.link-box-content-text-upper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 241px;
}

.tour-time {
  font-size: 14px;
  line-height: 18px;
  max-width: 36px;
  text-align: left;
  color: #161616;

}

.tour-title {
  display: flex;
  align-items: center;
  gap: $spacing-03;
  font-size: 15px;
  font-style: bold;
  margin-left: $spacing-04;
  max-width: 190px;
  text-align: left;
  color: #161616;
}

.link-box-content-text-lower {
  font-size: 13px;
  max-width: 230px;
  margin-top: $spacing-04;
  display: flex;
  gap: $spacing-02;
  color: #161616;
}

.arrow-right-details {
  display: flex;
  max-width: 15px;
  min-height: 20px;
  min-width: 20px;
  position: relative;
  margin-left: auto;
  width: 15px;
  color: #161616;
  align-items: center;
  justify-content: flex-start;
}


.text-decoration-none {
  text-decoration: none;
}

.booking-color {
  // background-color: #0f62fe;
  display: flex;
  flex: 1 1 auto; 
  width: $spacing-05;
  flex-direction: column;
  flex-shrink: 0;
}

.color-default {
  background-color: #0f62fe;
}

.color-blue {
  background-color: #0f62fe;
}
.color-pink {
  background-color: #F53D7A;
}

.color-green {
  background-color: #7ce60d;
}

.color-brown {
  background-color: #d07a01;
}

.color-black {
  background-color: #161616;
}

.color-yellow {
  background-color: #FFC107;
}

.flag-icon {
  width: 25px; 
  height: auto; 
}