@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/breakpoint' as *;

// TODO change this so when notification error appears, widht stays the same

.fixed-width-input {
  @include breakpoint-down('md') {
    max-width: 18rem;
  }
  @include breakpoint-down('lg') {
    max-width: 18rem;
  }
  max-width: 21rem;
}
