@use './overrides.scss';
@use '@carbon/react/scss/type' as *;


.calendar-bar-date {
  vertical-align: middle;

}
.calendar-bar-date-and-icon {
  display: flex;
  align-items: center;
  text-align: center;
}

.calendar-bar-calendar-icon {
  display: flex;
  margin-right: 24px;
  vertical-align: middle;
}

