@use './overrides.scss';
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;


.h2-bottom-margin {
  padding-left: $spacing-04;
  @include breakpoint-down('md') {
    margin-bottom: $spacing-07;
  }
  @include breakpoint-down('lg') {
    margin-bottom: $spacing-07;
  }
  margin-bottom: $spacing-08;
}

.bookings-page__r3 {
  display: grid;
  justify-content: center;
}

.bookings-page-calendar-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bookings-calendar-bar-center {
  display: flex;
  justify-content: center;
  padding-right: 12px;
  padding-left: 12px;    
  max-width: 40rem;
  min-width: 254px;
  max-height: 40px;
  align-items: center;
}

.bookings-page__calendar-chevron {
  align-items: center;
  display: flex;
  padding: $spacing-04;
  justify-content: center;
  max-width: 48px;
  height: 48px;
  margin: 0;
  
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time {
  position: absolute;
  top: 1px;
  left: 0;
}

.title {
  text-align: center;
}

.dropdown-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pax {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.gray-color {
  background-color: #F1F1F1;
  border-width: 1px;
  border-color: #D9D9D9;
  border-style: solid;
  color: #161616;
}

.no-bookings-message {
  display: grid;
  padding-top: $spacing-05;
  justify-content: center;
}