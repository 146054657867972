@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;

.main-tour-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.tour-page__r3 {
  display: grid;
  justify-content: center;
}

.upper-booking-section {
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notes-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $spacing-04;
}

.centered-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upper-button-container  {
  display: flex;
  gap: $spacing-05;
  justify-content: center;
  margin: $spacing-04;
  width: 359px;
}

.add-bookings-modal-stack {
  padding-top: $spacing-07;
}

.bottom-modal {
  position: fixed;
  bottom: 0;
  /* You can also set the left and right values to center the modal horizontally */
  left: 50%;
  transform: translateX(-50%);
}



.back-button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.back-button-text {
  color: #161616;
  text-decoration: underline;
}

.date-uk {
  display: block;
  text-align: center;
  padding-top: $spacing-04;
  margin-bottom: $spacing-07;
}

.tour-notes {
  padding-top: $spacing-02;
  display: flex;
  align-items: center; 
  min-width: 340px;
  background: #FEFAF7;
}

.wrapped-tour-note {
  display: flex;
  align-items: first baseline;
  gap: $spacing-03;
  max-width: 300px;
  margin-left: $spacing-04;
  margin-right: $spacing-05;
  margin-bottom: $spacing-05;
  margin-top: $spacing-04;
  white-space: pre-wrap;
  line-height: var(--cds-body-long-02-line-height,1.5);
}

.wrapped-tour-note-icon {
  flex-shrink: 0;
}
.show-more-button {
  margin-left: $spacing-03;
  padding: $spacing-03;
  border-style: none;
  color:#161616;
}


.attendees-info-guides-modal {
  display: flex;
  flex-direction: column;
  gap: $spacing-05;
}

.margin-button-4 {
  margin-bottom: $spacing-04;
}


.assignable-centered {
  display: flex;
  margin-top: $spacing-05;
  margin-bottom: $spacing-05;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.chosen-guide {
  font-size: 1.125rem;
  font-weight: 500;
  margin-left: $spacing-02;
  vertical-align: middle;
}