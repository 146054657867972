@use './overrides.scss';
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;



.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
}


.landing-page__subheading {
  padding-top: $spacing-02;
}

.landing-page__input {
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
}


.login--button {
  width: 100%;
  justify-content: center;
  padding: 0;
}
