@use '@carbon/react/scss/spacing' as *; 


.tile-group-container {
  margin-top: $spacing-07;
}


.tile-tour-customiser {
  margin-top: $spacing-05;
}

@media (min-width: 550px) {
  .tile-tour-customiser {
    margin-top: $spacing-05;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 540px;
  }
}

.tour-selector-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-05;

}

.color-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-02;
}

.square {
  width: 50px;
  height: 50px;
}