@use '@carbon/react/scss/spacing' as *;


.age-group-dropdown {
  margin-top: $spacing-07;
  max-width: 288px;
}

.fee-table {
  margin-top: $spacing-07;
}

