@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;


.heading-box {
  // margin-top: $spacing-07;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tour-heading-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 361px;
  padding-top: $spacing-04;
  padding-bottom: $spacing-04;
  padding-left: $spacing-05;
  padding-right: $spacing-05;
  border-left: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;  
  background-color: white;
}

.tour-heading-container {
  display: flex;
  flex-direction: column; 
  box-shadow: rgb(0 0 0/8%) 0 1px 0;
}

.booking-color-row {
  // background-color: #0f62fe;
  display: flex;
  flex: 1 1 auto; 
  width: 100%; 
  flex-direction: column;
  flex-shrink: 0;
  height: $spacing-05; 
}

.heading-box-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 361px;
  padding-top: $spacing-04;
  padding-bottom: $spacing-04;
  padding-left: $spacing-05;
  padding-right: $spacing-05;
  background-color: #0f62fe;
}

.right-icons {
  display: flex;
  align-self: flex-start;
  justify-content:space-between;
  padding-bottom: $spacing-06;
  gap: $spacing-02;
}

.reversed-color {
  background-color: transparent; /* Change to the color you want */
  color: #0f62fe; /* Change to the color you want */
}
.reversed-color:hover {
  background-color: transparent; /* Change to the color you want on hover */
  color: #0f62fe; /* Change to the color you want on hover */
}

.spacing-04-right {
  padding-right: $spacing-04;
}

.spacing-03-left {
  padding-left: $spacing-03;
}

.tour-heading-guide-group {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-04;
  gap: $spacing-04;
  font-size: 15px;
}