@use './overrides.scss';
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;

.profile-menu{
  position: absolute;
  top: 48px;
  background-color: #161616;
  width: 160px;
  transition-duration: 1.5s;
}

.profile-dropdown-icon {
  margin-left: $spacing-11;
}

.padding-right a {
  padding-right: $spacing-12;
}

.profile-dropdown {
  display: flex;
  align-items: center;
  margin: 10px auto;
}

.user-email {
  justify-content: center;
}

.profile-menu span {
  display: flex;
  align-items: center;
  padding-right: 0px;
  font-size: 80%;
}