@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;


.notification-margin {
  margin-bottom: $spacing-04;
  margin-top: $spacing-02;
}

.dropDown-submit {
  margin-left: $spacing-05;
  margin-bottom: $spacing-04;
}

.dropdown-buttons-group {
  display: flex;
  gap: $spacing-04;
  margin: 0 $spacing-04;
  margin-bottom: $spacing-04;
}

.edit-form {
  padding-top: $spacing-07;
  display: flex;
  flex-direction: column;
  gap: $spacing-08;
  // margin: 0 $spacing-04;
}

.flex-1 {
  flex: 1;
}

.edit-time-and-date-row {
  display: flex;
  // gap: $spacing-06;
}

.booking-phone-group {
  padding-top: $spacing-02;
  padding-left: $spacing-05;
  margin-bottom: $spacing-04;
  display: flex;
  align-items: center;
  gap: $spacing-03;
}

.booking-phone-element {
  text-decoration: none;
  color: #191919;
}